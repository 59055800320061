<template>
  <!--
    Used to display devices.
  -->
  <div class="deviceListVisualisation">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        v-if="devices"
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(devices)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(devices)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a key"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="vpnIpFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="vpnIpFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('vpnIpFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="deviceTypeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="deviceTypeFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('deviceTypeFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="applicationTypeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="applicationTypeFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('applicationTypeFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="uuidFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="uuidFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('uuidFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="iconCell"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <font-awesome-icon
              :class="`${ deviceIconColorByVpnIpAddress(props.dataItem.vpnIpAddress) } fa-2x`"
              :icon="`${ deviceIconByType(props.dataItem.deviceType) }`"
            />
          </td>
        </template>
        <template
          slot="vpnIpCell"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <template v-if="props.dataItem.vpnIpAddress">
              <CopyToClipboard :copy-data="computedVpnText(props.dataItem.vpnIpAddress)" />
            </template>
            <template v-else>
              {{ computedVpnText(props.dataItem.vpnIpAddress) }}
            </template>
          </td>
        </template>
        <template
          slot="nameCell"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <template v-if="!authenticationHasRole('zerolevel')">
              <router-link
                :to="deviceDetailLinkByUuid(props.dataItem.uuid)"
              >
                <strong
                  class="d-block"
                >{{ props.dataItem.name }}</strong>
              </router-link>
            </template>
            <template v-else>
              <strong
                class="d-block"
              >{{ props.dataItem.name }}</strong>
            </template>
          </td>
        </template>
      </Grid>
      <p v-else>
        {{ $t('noDataAvailable') }}
      </p>
    </template>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: 'DeviceList',
  components: {
    CopyToClipboard: () => import('@/components/CopyToClipboard.vue')
  },
   mixins: [
    authenticationMixin,
    kendoGridMixin
  ],
  props: {
    installationId: {
      type: String,
      required: false,
      default: ""
    },
    deviceToExclude: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      loading: true,
      devices: null,
      kgm_sort: [
        {
          "field": "name",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          filterable: false,
          cell: 'iconCell',
          width: '60px'
        },
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: 'Name',
          filterCell: "nameFilter",
          cell: 'nameCell'
        },
        {
          field: 'applicationType',
          filterable: true,
          filter: 'text',
          title: this.$t('applicationType'),
          filterCell: "applicationTypeFilter",
          hideOn: ['smDown']
        },
        {
          field: 'vpnIpAddress',
          filterable: true,
          filter: 'text',
          title: 'VPN IP',
          filterCell: 'vpnIpFilter',
          cell: 'vpnIpCell',
          hideOn: ['smDown']
        },
        {
          field: 'deviceType',
          filterable: true,
          filter: 'text',
          title: this.$t('deviceType'),
          filterCell: "deviceTypeFilter",
          hideOn: ['mdDown']
        },
       {
          field: 'uuid',
          filterable: true,
          filter: 'text',
          title: 'UUID',
          filterCell: "uuidFilter",
          hideOn: ['mdDown']
        }
      ]
    }
  },
  mounted () {
    this.getDevices();
  },
  methods: {
//#region Helper
    computedVpnText (ipAddress) {
      if (ipAddress == null) {
        return 'unavailable';
      }
      if (ipAddress.length == 0) {
        return 'unavailable';
      }
      return ipAddress;
    },
    computedVpnColor (ipAddress) {
      if (ipAddress == null) {
        return 'red';
      }
      if (ipAddress.length == 0) {
        return 'red';
      }
        return 'green';
    },
    deviceIconColorByVpnIpAddress (vpnIpAddress) {
      let hasVpnReturnValue = 'green';
      let hasNoVpnReturnValue = 'red';
      if (vpnIpAddress == null) {
        return hasNoVpnReturnValue;
      }
      if (vpnIpAddress.length == null) {
        return hasNoVpnReturnValue;
      }
      if (vpnIpAddress.length == 0) {
        return hasNoVpnReturnValue;
      }
      return hasVpnReturnValue;
    },
    deviceDetailLinkByUuid (uuid) {
      return `/installation/${ this.installationId }/devices/device/${ uuid }`;
    },
    shortenDeviceType (deviceType) {
      let indexOfMinus = deviceType.indexOf("-");
      if (indexOfMinus < 0) {
        return deviceType;
      }
      let shortenedDeviceType = deviceType.substring(0, indexOfMinus);
      return shortenedDeviceType;
    },
    showIfInactive (vpnIpAddress) {
      if (vpnIpAddress == null) {
        return true;
      }
      if (vpnIpAddress.length == null) {
        return true;
      }
      if (vpnIpAddress.length == 0) {
        return true;
      }
      return false;
    },
    deviceIconByType (deviceType) {
      if (deviceType == null) {
        return;
      }
      if (deviceType.length == null) {
        return;
      }
      if (deviceType.length == 0) {
        return;
      }
      switch (this.shortenDeviceType(deviceType)) {
        case 'odysseus':
          return 'microchip'; 
        case 'x86':
          return 'server'; 
        case 'mikrotik':
          return 'wifi'; 
        case 'odroid':
          return 'microchip'; 
        case 'webrelay':
          return 'charging-station'; 
        default:
          return 'network-wired'; 
      }
    },
//#endregion

//#region API-clls
    getDevices () {
      this.loading = true;
      this.axios.get(`/CentralDeviceManagement/GetFromInstallationId?installationId=${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.devices = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
//#endregion
  }
}
</script>

<style scoped>
.deviceItem {
  width: 49%;
  white-space: nowrap;
}
.deviceItem:nth-child(odd) {
  margin-right: 2%;
  margin-bottom: 2%;
}
.deviceItem > div {
  overflow: hidden;
}
.deviceItem i {
  font-size: 2.5rem;
  line-height: 60px;
  margin-left: 2px;
}
.deviceItem ul {
  padding: 0 0 0 3.25rem;
  list-style: none;
  margin: 0;
}
@media (min-width: 720px) {
  .deviceListVisualisation .deviceItem {
    width: 200px;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
